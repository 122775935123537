.threecolumn {
    flex: 1;
    padding: 10px;
}

.threecolumn h3 {
    margin-top: 0;
    font-size: 1.4rem;
}

.threecolumn p {
    margin: 10px 0;
}


.map-frame {
    border: 2px solid #ccc;        /* Frame with a solid border */
    border-radius: 3px;           /* Optional: rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow for depth */
    background-color: #f9f9f9;     /* Background color for the frame */
}



@media (max-width: 900px) {
    .twocols-special {
        display: block;
        margin: 0px 5%;
    }
}
  

.regular-special {
    display: flex;
    flex-direction: column;
    justify-content: center; /* This centers the content vertically */
}

.twocols-title-special {
    margin-top: 30px
}



.venue-types {
    /* margin: 30px 120px; */
}

.image-caption {
    /* font-size: 0.8rem; */
    color: #464646;
    margin-top: 5px;
}




.accommodation-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 15px;
    justify-content: center;
}

.accommodation-card {
    flex: 1 1 calc(33.333% - 20px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    max-width: 250px;
    min-width: 200px;
    background-color: white;
    color: black;
}

.accommodation-card a {
    text-decoration: none;
    color: black;
}

.accommodation-card:hover {
    transform: translateY(-4px);
}


.accommodation-image {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
}

.accommodation-info {
    padding: 15px;
}

.accommodation-info h3 {
    font-size: 20px;
    margin: 0 0 10px;
}

.accommodation-info p {
    font-size: 16px;
    margin: 5px 0;
}

@media (max-width: 768px) {
    .accommodation-card {
        min-width: 140px;
    }
}
  
