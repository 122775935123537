
.leuven-describ {
    margin-top: 40px;
}


.gallery-pictures {
    display: flex;
    flex-wrap: wrap;
    gap: 5px; 
    padding: 20px;
    justify-content: center;
}

.picture-section {
    position: relative;
    width: 300px; /* Adjust the width as needed */
    /* margin-bottom: 20px; */
    height: 200px;
    overflow: hidden;
    /* border-radius: 10px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.picture-section a {
    text-decoration: none;
    color: inherit; /* Ensures links don't have underlines and inherit text color */
}

.picture-image {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 1%;
    object-fit: cover; /* Ensures the image covers the container without distortion */
    object-position: center; /* Centers the image within the container */
    transition: transform 0.3s ease; /* Adds a smooth zoom effect on hover */
}

.picture-section:hover {
    transform: scale(1.02);
}

.picture-info {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.picture-section:hover .picture-info {
    opacity: 1;
}

.picture-info h3 {
    margin: 5px 0;
    font-size: 1.2rem;
}

.picture-info p {
    margin: 1px 0;
    color: #ddd;
}

.picture-info a:hover {
    text-decoration: underline;
}


@media (max-width: 1400px) {

    .picture-section {
        width: 250px;
    }
}


