.footer {
    background-color: black;
    color: white;
    padding: 10px 0;
    font-size: 1.2rem;
}

.footer p {
    margin: 20px 0px;
}

.footer-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
    padding: 5px;
    /* border-bottom: 1px solid #444; */
    flex-wrap: wrap; /* Allows the content to wrap when needed */
}

.footer-section {
    flex: 1;
    padding: 0 20px;
}

.footer-logo, .conductor-logo {
    max-height: 50px;
    display: block;
    margin-bottom: 10px;
}

.footer-logo {
    max-height: 80px;
    /* White box */
    /* padding: 10px; */
    background-color: white;
    border-radius: 10px;
}

.footer-section h3 {
    margin-bottom: 10px;
    margin-top: 12px;
    font-size: 1.2rem;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 5px;
    font-size: .9rem;
}

.footer-section ul li a {
    color: white;
    text-decoration: none;
}

.footer-section ul li a:hover {
    text-decoration: underline;
}

.footer-bottom {
    text-align: center;
    /* padding: 10px 0; */
}

.footer-links {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.footer-links li {
    margin: 0 10px;
}

.footer-links li a {
    color: white;
    text-decoration: none;
}

.footer-links li a:hover {
    text-decoration: underline;
}

.social-icons {
    display: flex;
    /* justify-content: center; */
    gap:10px;
    font-size: 30px;
}

.social-icons a {
    color: white;
    text-decoration: none;
}

/* Purple */
.social-icons a:hover {
    color: #8a2be2; 
}

/* .social-icons a img {
    max-height: 45px;
} */

.footer-logo-container {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
}





/* New: Flexbox setup for the original large screen layout */
.footer-section-container {
    display: flex;
    justify-content: flex-start; /* Align the group of three sections to the left */
    flex: 3; /* Give more space to the group of three */
    gap: 20px; /* Add some space between the sections */
}

.footer-section:first-child {
    flex: 1; /* The "Sponsored by" section */
}


/* Media query for screens smaller than 768px */
@media (max-width: 768px) {
    .footer-container {
        flex-direction: column; /* Stack the sections vertically */
        align-items: center;
    }

    .footer-section {
        padding: 10px 0;
        text-align: center;
    }

    /* Specific styling for the three sections to stay in one row */
    .footer-section:nth-child(2),
    .footer-section:nth-child(3),
    .footer-section:nth-child(4) {
        order: 1; /* Ensure these three sections are grouped together at the top */
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
    }

    /* Ensure these three sections are side by side */
    .footer-section-container {
        display: flex;
        justify-content: space-around;
        width: 100%;
    }

    /* Move the Sponsored by section to the bottom */
    .footer-section:nth-child(1) {
        order: 2; /* This will move it below the first three sections */
        width: 100%; /* Ensure it takes up full width */
    }

    .footer-logo-container {
        justify-content: center;
        flex-wrap: wrap; /* Allow logos to wrap if they don't fit in one line */
    }

    .social-icons {
        justify-content: center;
    }

    .footer-logo {
        display: none;
    }

    .footer p {
        margin: 0px 0px 15px 0px;
    }
    
}