.App {
  /* font-size: 1.5rem; */
}

.App-header {
  background-color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.App-Home {
  /* background-color: #00274b;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0px 10%; */
  /* align-items: center;
  justify-content: center; */
  /* font-size: calc(10px + 2vmin); */
}


.HomeCont {
  display: flex;
  flex-direction: column;
  padding: 50px 15%;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center;
  height: 100vh; */
  /* background: url('path/to/your/image.png') no-repeat center center; */
  /* background-size: cover; */
}

.HomeWideCont {
  display: flex;
  flex-direction: column;
  padding: 50px 2% !important;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center;
  height: 100vh; */
  /* background: url('path/to/your/image.png') no-repeat center center; */
  /* background-size: cover; */
}


.ContType1{
  /* max-height: 1000px; */
  background-color: #00274b;
  color: white
}

.ContType2{
  /* max-height: 1000px; */
  background-color: #f1eee0;
}

.ContType3{
  /* max-height: 1000px; */
  background-color: #00407a;
  color: white;
}

.ContType3 a, .ContType1 a {
  color: white;
}


.ContType4{
  /* max-height: 1000px; */
  /* background-color: White; */
  color: Black;
}



.title-top-pages {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  padding: 50px 10% 10px 10%;
  font-size: 1.9rem;
  font-weight: 600;
  /* background: url('path/to/your/image.png') no-repeat center center; */
  background-size: cover;
  color: black;
}


.regular-title {
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 1.5;
  padding-bottom: 10px;
  text-align: center;
}

.main-regular {
  margin: 0px 15%;
}


.Page-Background {
  background: url('./assets/images/background1.png') repeat-x 0 -5em transparent;
  bottom: auto;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
  pointer-events: none;
}






/* TWO COLUMNS */
.twocols-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* padding: 20px; */
  gap: 40px;
}

.twocols-container-inverse {
  flex-wrap: wrap-reverse !important;
}

.twocols-container div {
  max-width: 600px;
}


.twocols-title {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}


.twocols-image{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.twocols-image img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  height: auto;
  border-radius: 10px;
}

.twocols-post {
  font-style: italic;
  /* margin-bottom: 20px; */
  margin-top: 10px;
  text-align: center;
}


@media (max-width: 1100px) {
  .twocols-container div {
    max-width: 450px;
  }
}







/* BIG CARD */

.card-big {
  /* background: white; */
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: inset 0 -.2em 1em rgba(52, 52, 52, 0.1),
              0 0  0 3px #1d8eb037,
              0.3em 0.3em 1em rgba(116, 116, 116, 0.3);
  max-width: 1000px;
  text-align: center;
  overflow: hidden;
  transition: transform 0.2s;
  text-decoration: none;
  margin: 0 auto 60px auto;
  padding: 10px 5% 15px 5%;
}







.filter-container {
  display: flex;
  gap: 10px;
  padding-left: 5%;
  /* margin-bottom: 20px; */
}

.filter-button {
  display: flex;
  gap: 10px;
  padding-left: 1%;
  /* margin-bottom: 20px; */
}

.filter-button button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.filter-button button:hover {
  background-color: #b3b3b3;
}

.filter-button button.active {
  background-color: #0066cc;
  color: white;
}

.filter-title {
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  align-content: center;
}


@media (max-width: 500px) {
  .filter-container{
    padding-left: 0%;
  }
}