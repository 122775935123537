


.App-ComingSoon {
    text-align: center;
}
  
.ComingSoon-header {
    background-color: #00274b;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}
  
  