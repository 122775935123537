.card-slider-container {
    display: flex;
    overflow-x: auto;
    padding: 20px;
    gap: 10px; /* Space between cards */
    cursor: grab;
    user-select: none; /* Prevents text selection */
}

.card-slider-container:active {
    cursor: grabbing;
}

.card-slider {
    flex: 0 0 auto; /* Prevents flexbox from stretching */
    width: 250px; /* Adjust the width as needed */
    height: 400px; /* Defined card height */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background-color: white;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column; /* Ensures consistent layout with image and info stacked */
}

.card-slider:hover {
    transform: scale(1.05); /* Zoom effect on hover */
}

.card-image {
    width: 100%;
    height: 60%; /* Adjust this to control the space image takes in the card */
    object-fit: cover; /* Ensures the image covers the available space without distortion */
    display: block;
    user-select: none; /* Prevents image selection */
}

.card-info {
    flex-grow: 1;
    padding: 10px;
    text-align: center;
    user-select: none; /* Prevents text selection */
    display: flex;
    flex-direction: column; /* Aligns content vertically */
    justify-content: center; /* Centers content vertically */
}

.card-info h3 {
    margin: 5px 0;
    font-size: 1.2rem;
    color: black;
}

.card-info p {
    margin: 5px 0;
    color: #666;
}

.card-info a {
    color: #0066cc;
    text-decoration: none;
    margin-top: 10px;
    display: block;
    user-select: none; /* Prevents link text selection */
}

.card-info a:hover {
    text-decoration: underline;
}
