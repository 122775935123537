.symp-section {
    padding: 20px 0;
    margin-bottom: 20px; /* Space below the section */
}

.symp-details {
    display: flex;
    flex-wrap: wrap; /* Allow details to wrap on smaller screens */
    gap: 20px; /* Space between details */
    margin-top: 20px; /* Space between title and details */
    justify-content: center; /* Center elements horizontally */
    align-items: center; /* Center elements vertically */
}

.symp-details p {
    margin: 0; /* Remove default margin */
    font-size: 1.5rem; /* Standard font size */
    color: #333; /* Dark text color */
}

.symp-time {
    color: #0066cc; /* Primary color for time */
}

.symp-room {
    color: #cc0000; /* Accent color for room */
}

.topic-main-author {
    font-weight: bold; /* Bold text */
}

.sym-topic-title {
    font-size: 25px;
    font-weight: bold;
    /* padding-bottom: 10px; */
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;
}
