.navBar {
    padding: 18px 0px;
    background: #00407a;
}

.navContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 5%;
    position: relative;
}

.logo {
    display: flex;
    align-items: center;
}

.sponsored-link {
    display: flex;
    text-decoration: none;
    padding-left: 100px;
}

.logo a {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.logo img {
    height: 50px; /* Adjust as needed */
}

.logo-text {
    display: flex;
    align-items: center;
    margin-left: 10px; /* Adjust as needed */
}


.logo-bar {
    width: 4px;
    height: 50px; /* Adjust as needed */
    margin-right: 10px;
}

.logo-text .logo-bar {
    background: linear-gradient(to bottom, #0073e6, #00407a); /* Gradient from blue to darker blue */
}

.logo-description {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: 600;
}

.logo-text .logo-description{
    color: white;
}


/* Effect */
/* .logo-text:hover .logo-description   {
    color: #8a2be2;
}

.logo-text:hover .logo-bar {
    background: linear-gradient(to bottom, #8a2be2, #8a2be2); 
} */



.line1, .line2 {
    margin: 0;
    padding: 0;
}


.menuContainer {
    display: flex;
    align-items: center;
    gap: 3px;
}

.menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.menu li {
    margin: 0 15px;
}

.menu li a {
    color: white;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
}

.menu li a.active {
    text-decoration: underline;
}

.menu li a:hover {
    text-decoration: underline;
    color: #eee;
}

.registerButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 0px 0px 30px;
}

.registerButton button {
    appearance: button;
    backface-visibility: hidden;
    background-color: #ffffff;
    border-radius: 20px;
    border-width: 0;
    box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .1) 0 2px 5px 0, rgba(0, 0, 0, .07) 0 1px 1px 0;
    box-sizing: border-box;
    color: #00407a;
    cursor: pointer;
    font-size: 105%;
    font-weight: 600;
    height: 50px;
    line-height: 1.15;
    outline: none;
    overflow: hidden;
    padding: 0 30px;
    position: relative;
    text-align: center;
    text-transform: none;
    transform: translateZ(0);
    transition: all .2s, box-shadow .08s ease-in;
    user-select: none;
    width: 100%;
}

.registerButton button:disabled {
    cursor: default;
}

.registerButton button:focus {
    box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .2) 0 6px 15px 0, rgba(0, 0, 0, .1) 0 2px 2px 0, rgba(50, 151, 211, .3) 0 0 0 4px;
}

.registerButton button:hover {
    background-color: #ddd; /* Change hover color if needed */
}

.burger {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.bar1, .bar2, .bar3 {
    width: 100%;
    height: 4px;
    background-color: white;
    transition: all 0.3s;
}

.bar1.open {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.bar2.open {
    opacity: 0;
}

.bar3.open {
    transform: rotate(45deg) translate(-5px, -6px);
}


.menuContainer.open {
    display: flex;
}




/* Media query for intermediate screen width */
@media (max-width: 1200px) {
    .menu li {
        margin: 0 10px;
    }

    .menu li a {
        font-size: 15px;
    }

    .registerButton button {
        font-size: 90%;
        padding: 0 20px;
    }
}



@media (max-width: 950px) {
    .menuContainer {
        display: flex;
        position: absolute;
        top: 60px;
        right: 0;
        width: 100%;
        background-color: #00407a;
        flex-direction: column;
        align-items: center; /* Center horizontally */
        justify-content: center; /* Center vertically */
        padding: 0;
        z-index: 999;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
        transition: max-height 0.3s ease, opacity 0.3s ease, visibility 0.3s;
    }

    .menu ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center; /* Center the menu items horizontally */
        justify-content: center; /* Center the menu items vertically */
    }

    .menu li {
        margin: 10px 0;
        text-align: center;
        padding: 0 10%;
    }

    .menu li a {
        font-size: 20px;
        color: white;
        text-decoration: none;
        font-weight: 600;
    }

    .registerButton {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 15px 0;
    }

    .burger {
        display: flex;
    }
    
    .menuContainer.open {
        max-height: 400px; /* Adjust this to the expected height of your menu */
        opacity: 1;
        visibility: visible;
        padding: 10px 0;
    }
}




.logo-svg {
    width: 60px;  /* Set the desired width */
    height: auto;  /* This ensures the aspect ratio is maintained */
}