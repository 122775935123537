.twocols-title-sponsor {
    font-size: 35px;
    font-weight: 600;
    text-align: center;
}

.twocols-title-sponsor a{
    color: black;
    text-decoration: none;
}


.twocols-title-sponsor a:hover{
    color: #ff6600;
    text-decoration: underline;
}

.pdf-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 15px;
    justify-content: center;
}