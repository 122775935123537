.keynote-title {
  /* font-size: 35px; */
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.card-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px 0px;
    gap: 50px;
}

.card {
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 250px;
    text-align: center;
    overflow: hidden;
    transition: transform 0.2s;
    text-decoration: none;
}

/* Create white borders */
.cardWhiteStyle {
    position: relative;
    border: 4px solid #ffffff;
    color: black;
    /* filter: drop-shadow(0 0 2px rgba(241, 241, 241, 0.496)); */
    /* box-shadow: 0 0 4px rgba(241, 241, 241, 0.8); */
}


.card a {
    text-decoration: none; /* Remove underline from links */
    color: inherit; /* Inherit color from parent element */
}

.card img {
    width: 100%;
    height: auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.card-title {
    font-size: 1.1rem;
    font-weight: bold;
    margin: 15px 0 5px;
}

.card-description {
    font-size: .7rem;
    color: #555;
    padding: 0 15px 15px;
}

.card:hover {
    transform: translateY(-10px);
}

.card-title:hover {
    text-decoration: underline;
}


@media (max-width: 768px) {
    .card-container {
        padding: 20px 0px;
        gap: 20px;
    }

    .card {
        max-width: 160px;
    }
}