/* Disable links */
.isDisabled {
    pointer-events: none;
    /* text-decoration: none; */
}


.twocols-container-contact {
    padding: 0px 0px;
}


.trombinoscope-container {
    width: 80%;
    margin: 0 auto;
    padding: 10px 0px 0px 0px !important;
}

.team-section {
    margin-bottom: 20px;
}

.team-title {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 0px;
    padding-left: 40px;
    position: relative;
    display: inline-block;
}

.team-title::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #333;
    margin-top: 5px;
}

.team-members {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.team-members a {
    text-decoration: none;
    color: black;
}

.team-members a:hover h3 {
    text-decoration: underline;
    color: black;
}

.team-member {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
    width: 160px;
    margin: 15px 5px;
}

.member-image {
    width: 100%;       /* Ensures the image covers the full width of the container */
    height: 100%;      /* Ensures the image covers the full height of the container */
    max-height: 200px; /* Adjust this value to your desired maximum height */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; 
    object-fit: cover;
    display: block;
    margin: 0 auto; /* Centers the image within its container */
}

.member-info {
    padding: 5px 5px 10px 5px;
}

.member-name {
    font-size: 17px;
    font-weight: bold;
    margin: 5px 0;
}

.member-role {
    font-size: 15px;
    color: #666;
    margin: 6px 0;
}

@media (min-width: 1400px) {
    .trombinoscope-container {
        margin-left: 20%;
    }
}


@media (max-width: 768px) {
    .team-members {
        gap: 15px;
    }

    .member-image {
        max-height: 150px; /* Adjust this value to your desired maximum height */
    }

    .team-members a {
        max-width: 150px;
    }
}



/* Research group links */
.research-title {
    margin-top: 50px;
    margin-bottom: 10px;
}
.research-groups-container {
    padding: 30px;
    text-align: center; /* Centers the title */
}


.research-groups {
    display: flex;
    flex-wrap: wrap; /* Allows wrapping into multiple rows if needed */
    justify-content: center; /* Centers the boxes within the container */
    gap: 20px; /* Adds space between the group cards */
    margin: 0 auto; /* Centers the group of boxes within its container */
}

.research-group {
    background-color: #f5f5f5; /* Light background color for the frames */
    border: 2px solid #ddd; /* Border around each group */
    border-radius: 8px; /* Rounded corners for a more polished look */
    padding: 20px;
    text-align: center;
    flex: 1 1 250px; /* Ensures flexibility with a minimum width of 250px */
    max-width: 300px; /* Maximum width to maintain a nice layout */
    transition: transform 0.3s; /* Smooth transition for hover effect */
    text-decoration: none; /* Remove underline from links */
    color: inherit; /* Inherit text color */
    margin: 10px; /* Adds margin around each research group */
}

.research-group:hover {
    transform: translateY(-5px); /* Slight upward movement on hover */
}

.research-group-title {
    font-size: 1.1em;
}

.research-group-description {
    font-size: 1em;
    color: #666;
}

/* Responsive layout: stack items vertically on small screens */
@media (max-width: 768px) {
    .research-groups {
        flex-direction: column;
        align-items: center;
    }

    .research-group {
        max-width: 100%; /* Full width for small screens */
    }
}







/* Contact part */

.contact-info {
    padding: 5px 0px;
    max-width: 400px; /* Adjust the width as needed */
    margin: 0 auto; /* Center the container */
    font-style: italic;
}

.contact-info p {
    margin: 0px;
}

.contact-info a {
    color: #0066cc;
    text-decoration: none;
}

.contact-info a:hover {
    text-decoration: underline;
}


.vertical-line {
    width: 1px;
    background-color: #ccc;
    margin: 0px;
}

.p-justify-text {
  text-align: justify;
  text-justify: inter-word;
}

@media (max-width: 900px) {
    .vertical-line {
        display: none;
    }
}